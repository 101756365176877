import { useState, useEffect } from "react";
import physic from "../assets/images/first.png";
import InputText from "../components/InputText";
import Dropdown from "../components/Dropdown/Dropdown";
import Button from "../components/Button";
import instance from "../api/axios";
import mixpanel from "../mixpanel"
import { createUser, getAllTherapist } from "../api/route";
import { useNavigate, useParams } from "react-router-dom";
import { validateMobile, validatePinCode } from "../components/validations";

const Form = () => {
  const navigate = useNavigate();
  const { therapistId } = useParams();

  const cityOptions = ["Mumbai", "Pune", "Gurgaon", "New Delhi", "Noida"];

  const [allFieldsFilled, setAllFieldsFilled] = useState(false);
  const [warnings, setWarnings] = useState({ mobile: "", pinCode: "" });
  const [formData, setFormData] = useState({
    user_name: "",
    user_phone: "",
    city: "Mumbai",
    pinCode: "",
  });

  const [utmParams, setUtmParams] = useState({});
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const utms = {
      utm_source_custom: searchParams.get("utm_source"),
      utm_medium_custom: searchParams.get("utm_medium"),
      utm_campaign_custom: searchParams.get("utm_campaign"),
      utm_term_custom: searchParams.get("utm_term"),
      utm_addgroup_custom: searchParams.get("utm_adgroup") || searchParams.get("utm_adgroup_id"),
      gclid_custom: searchParams.get("gclid"),
    };
    setUtmParams(utms);
  }, []);
  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    const newFormData = { ...formData, [name]: value };
    if (name === "pinCode" && value === "111111") {
      // Auto-fill name and phone when pin code is 111111
      newFormData.user_name = "Test";
      newFormData.user_phone = "9429690435";
    }

    if (name === "user_phone") {
      setWarnings({ ...warnings, mobile: validateMobile(value) });
    } else if (name === "pinCode") {
      setWarnings({ ...warnings, pinCode: validatePinCode(value) });
    }
    setFormData(newFormData);
  };

  const checkAllFieldsFilled = () => {
    const filled =
      Object.values(formData).every((value, index) => {
        return value.trim() !== "";
      }) &&
      !warnings.mobile &&
      !warnings.pinCode;
    setAllFieldsFilled(filled);
    console.log("All fields filled:", filled);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const userObj = {
      name: formData.user_name,
      address: formData.city,
      pinCode: formData.pinCode,
      phone: formData.user_phone,
      ...utmParams, 
    };
    console.log('obj',userObj);

    try {
      // Submit user data to create a new user
      const response = await instance.post(createUser, userObj);
      console.log("User created:", response.data);
      sessionStorage.setItem("user", JSON.stringify(userObj));

      // Mixpanel track event
    mixpanel.track('Button Clicked - Sign Up', {
      'Name': formData.user_name,
      'Mobile Number': formData.user_phone,
      'City': formData.city,
      'Pin Code': formData.pinCode
    });
  
      // Attempt to fetch therapists based on the pin code
      const therapistResponse = await instance.get(`${getAllTherapist}?pinCode=${formData.pinCode}`);
      console.log("Therapist by pinCode:", therapistResponse.data.therapists);
  
      if (therapistId) {
        navigate(`/therapist-slot/${therapistId}`, { state: { ...formData } });
      } else {
        const therapistResponse = await instance.get(`${getAllTherapist}?pinCode=${formData.pinCode}`);
        if (therapistResponse.data.therapists && therapistResponse.data.therapists.length > 0) {
          navigate("/booking-slot", { state: { ...formData } });
        } else {
          navigate("/empty-slot");
        }
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };
  
  

  const handleBackButtonClick = () => {
    setFormData({
      user_name: "",
      user_phone: "",
      city: "",
      pinCode: "",
    });

    window.location.href = "https://www.physica.fit";
  };

  useEffect(() => {
    checkAllFieldsFilled();
  }, [formData]);

  return (
    <form className="lg:w-full flex justify-center" onSubmit={handleSubmit}>
      <div className="md:w-1/2 change_mobile">
        <div
          className=" cursor-pointer"
          style={{ padding: "10px", paddingLeft: "22px" }}
          onClick={handleBackButtonClick}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.10841 0.228163C7.39181 0.531457 7.39082 1.02214 7.10619 1.32413L2.48697 6.22512H15.2727L15.3714 6.2322C15.7264 6.28351 16 6.60775 16 7.00009C16 7.42809 15.6744 7.77505 15.2727 7.77505H2.48848L7.10624 12.6759L7.17682 12.7627C7.38866 13.0656 7.36602 13.4962 7.10837 13.7719C6.82494 14.0752 6.36446 14.0762 6.07986 13.7742L0.213264 7.5486L0.210346 7.54501C0.187782 7.5207 0.166718 7.49481 0.147311 7.4675L0.142491 7.46157C-0.0698261 7.15771 -0.046217 6.72568 0.213306 6.45032L6.0799 0.225803L6.16162 0.150955C6.44679 -0.0734968 6.85077 -0.0475585 7.10841 0.228163Z"
              fill="#393938"
            />
          </svg>
        </div>
        <div className=" flex justify-center md:w-full">
          <img src={physic} className="w-full" alt="image not found" />
        </div>
        <div className="form_wrapper">
          <div className="p-4 mt-2 font-semibold leading-27.24 tracking-tighter text-xl text-center">
            <h1
              style={{ fontSize: "20px", color: "#393938", fontWeight: "600" }}
            >
              Book An At Home Session With Our Physiotherapist{" "}
              <span className="text-orange-500">For ₹899!</span>
            </h1>
          </div>
          <div style={{ fontSize: "14px", color: "black", fontWeight: "600" }}>
            <InputText
              name="user_name"
              label="Name"
              placeholder="Enter Your Name"
              value={formData.user_name}
              onChange={(e) => handleInputChange("user_name", e.target.value)}
            />
            <InputText
              name="user_phone"
              warnings={warnings.mobile}
              label="Mobile Number"
              placeholder="Enter Your Mobile Number"
              value={formData.user_phone}
              onChange={(e) => handleInputChange("user_phone", e.target.value)}
            />

            <Dropdown
              value={formData.city}
              data={cityOptions}
              callback={(e) => handleInputChange("city", e)}
            />
            <InputText
              name="pinCode"
              warnings={warnings.pinCode}
              label="Pin Code"
              value={formData.pinCode}
              placeholder="Enter Your Pin Code"
              onChange={(e) => handleInputChange("pinCode", e.target.value)}
            />
          </div>
          <div className="flex justify-center mt-8">
            {allFieldsFilled ? (
              <Button
                btnName="Proceed"
                style={
                  "text-center text-white font-semibold text-lg px-6 py-2 my-4 bg-orange-500 rounded-xl"
                }
              />
            ) : (
              <Button
                btnName="Continue"
                style={
                  "text-center text-white font-semibold text-lg px-6 py-2 my-4 bg-gray-400 rounded-xl"
                }
                disabled
              />
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
