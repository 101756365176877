import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { FaCheck, FaCalendarAlt, FaHome } from 'react-icons/fa';
import '../css/AppointmentConfirmation.css';

const AppointmentConfirmation = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Data received in AppointmentConfirmation:", state);
  }, [state]);

  if (!state || !state.bookingData) {
    console.error("No booking data received.");
    return <p>Booking details not available</p>;
  }
  const userDetail = sessionStorage.getItem("user");
  const user = JSON.parse(userDetail);
  const { booking_id, user_name, therapist_name, user_phone, user_address, start_at, end_at, current_payment } = state.bookingData;

  // Format the date & time from `start_at`
  const appointmentDate = new Date(start_at).toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  const appointmentTime = new Date(start_at).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  return (
    <div className="container">
      <div className="confirmation-header">
        <div className="checkmark-circle">
          <FaCheck className="check-icon" />
        </div>
        <h2>Appointment Confirmed!</h2>
      </div>

      <div className="content-container">
        <h3 className="session-title">Physiotherapy Session with Dr. {therapist_name}</h3>

        <div className="details-row">
          <div className="detail-column">
            <span className="detail-label">Name</span>
            <span className="detail-value">{user_name}</span>
          </div>
          <div className="detail-column">
            <span className="detail-label">Mobile</span>
            <span className="detail-value">{user_phone}</span>
          </div>
        </div>

        <div className="details-row">
        <div className="detail-column">
            <span className="detail-label">Pin Code</span>
            <span className="detail-value">{user.pinCode}</span>
          </div>
          <div className="detail-column">
            <span className="detail-label">Fees</span>
            <span className="detail-value">₹{current_payment}</span>
          </div>
        </div>

        <hr className="divider" />

        <div className="icon-detail-row">
          <FaCalendarAlt size={24} className="icon" />
          <div>
            <span className="detail-label">Date & Time</span>
            <span className="detail-value1">{appointmentDate} | {appointmentTime}</span>
          </div>
        </div>

        <hr className="divider" />

        <div className="icon-detail-row">
          <FaHome size={24} className="icon" />
          <div>
            <span className="detail-label">Complete Address</span>
            <span className="detail-value1">{user_address}</span>
          </div>
        </div>

        <div className="message-container">
          <p className="message-text">
          An appointment confirmation has been sent to your WhatsApp. If
          you have not received it yet, please contact us +918104974290
          </p>
        </div>

        <div className="appointment-id-container">
          <span className="appointment-id-label">Appointment ID</span>
          <span className="appointment-id-value">{booking_id}</span>
        </div>

        <button className="back-button" onClick={() => navigate("/")}>
          Back To Home
        </button>
      </div>
    </div>
  );
};

export default AppointmentConfirmation;
