import React, { useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import physic from "../assets/images/first.png";
import InputText from "../components/InputText";
import Button from "../components/Button";
import { FaEdit } from "react-icons/fa";

const PackageForm = () => {
    const [formData, setFormData] = useState({
        user_name: "",
        user_phone: "",
        package_amount: "",
        sessions_booked: "",
        sessions_per_week: "",  // ✅ Change from 1 to "" (empty)
        package_start_date: "", // ✅ Change from new Date() to "" (empty)
        therapist_name: "",
    });

    const [fetchedData, setFetchedData] = useState(null);
    const [step, setStep] = useState(1); // Step 1: Fill form, Step 2: Preview & Edit
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [isDatePickerOpen, setDatePickerOpen] = useState(false);
    // Handle Input Change for all fields
    const handleInputChange = (field, value) => {
        if (field === "sessions_per_week") {
            if (value === "") {
                setFormData({ ...formData, [field]: "" }); // Keep empty instead of NaN
                return;
            }

            const num = parseInt(value, 10);
            if (!isNaN(num)) {
                value = num < 1 ? 1 : num > 7 ? 7 : num; // Keep within range
            } else {
                value = "";
            }
        }
        setFormData({ ...formData, [field]: value });
    };

    const handleProceed = (e) => {
        e.preventDefault(); // ✅ Prevents page refresh
        fetchUserDetails(); // ✅ Calls API to fetch user data
    };
    // Fetch User Details & Combine with User-Filled Data
    const fetchUserDetails = async () => {
        setLoading(true);
        setError("");

        // ✅ Ensure phone number always has +91 prefix
        const formattedPhone = formatPhoneNumber(formData.user_phone);

        try {
            console.log("Fetching user details for:", formattedPhone);

            const response = await axios.post(
                "https://api.physica.fit/api/v1/package/fetch-user",
                { phone_no: formattedPhone }
            );

            console.log("Fetched Data:", response.data);

            setFetchedData(response.data);

            // Merge fetched data with user input
            setFormData((prev) => ({
                ...prev,
                user_name: response.data.patient_name || prev.user_name,
                therapist_name: response.data.doctor_id || prev.therapist_name,
                package_amount: response.data.package_amount || prev.package_amount,
                sessions_booked: response.data.sessions_booked || prev.sessions_booked,
                sessions_per_week: response.data.sessions_per_week || prev.sessions_per_week,
            }));

            setStep(2); // Move to Preview & Edit Step
        } catch (err) {
            console.error("Fetch Error:", err.response?.data || err.message);
            setError("User not found. Please enter details manually.");
            setFetchedData(null);
            setStep(2);
        }

        setLoading(false);
    };

    const formatPhoneNumber = (phone) => {
        return phone.startsWith("+") ? phone : `+91${phone}`;
    };
    // Final Submit API Call
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");
    
        // Validate & Format Phone Number
        const formattedPhone = formatPhoneNumber(formData.user_phone);
        console.log("Formatted Phone:", formattedPhone);
    
        // ✅ Always use manually entered values when API user fetch fails
        const patientId = fetchedData?.user_id || formData.user_id || "NEW_PATIENT";
        const therapistId = fetchedData?.doctor_id || formData.therapist_id || "UNKNOWN";
        const patientStatus = fetchedData?.user_status || formData.user_status || "Ongoing";
    
       
    
        // Calculate per-session amount
        const perSessionAmount = formData.sessions_booked > 0 ? (formData.package_amount / formData.sessions_booked).toFixed(2) : "0.00";
    
        // Calculate package end date based on sessions per week
        const startDate = new Date(formData.package_start_date);
        const totalWeeks = Math.ceil(formData.sessions_booked / formData.sessions_per_week);
        const packageEndDate = new Date(startDate);
        packageEndDate.setDate(packageEndDate.getDate() + totalWeeks * 7);
    
        const packageData = {
            date_of_purchase: new Date().toISOString().split("T")[0],
            patient_id: patientId, // ✅ Now correctly uses manually entered ID
            patient_name: formData.user_name,
            therapist_id: therapistId, // ✅ Now correctly uses manually entered therapist ID
            patient_mobile_number: formData.user_phone,
            package_amount: formData.package_amount,
            per_session_amount: perSessionAmount,
            date_of_package_start: formData.package_start_date.toISOString().split("T")[0],
            date_of_package_end: packageEndDate.toISOString().split("T")[0],
            sessions_booked: formData.sessions_booked,
            sessions_per_week: formData.sessions_per_week,
            package_status: "Active",
            patient_status: patientStatus, // ✅ Now correctly uses manually entered user status
            payment_status: "Pending",
            therapist_name: formData.therapist_name,
        };
    
        console.log("📤 Data Sent to API:", packageData);
    
        try {
            const response = await axios.post(
                "https://api.physica.fit/api/v1/package/create-package",
                packageData
            );
            console.log("✅ Package Created:", response.data);
            alert("Package created successfully!");
    
            setStep(1);
            setFormData({
                user_name: "",
                user_phone: "",
                package_amount: "",
                sessions_booked: "",
                sessions_per_week: "",
                package_start_date: "",
                therapist_name: "",
                user_id: "",  // ✅ Reset manually entered ID
                therapist_id: "",  // ✅ Reset manually entered therapist ID
                user_status: "",  // ✅ Reset manually entered status
            });
        } catch (err) {
            console.error("❌ Error:", err.response?.data || err.message);
            setError("Error submitting package. Try again.");
        }
    
        setLoading(false);
    };
    

    return (
        <form className="lg:w-full flex justify-center">
            <div className="w-full max-w-2xl mx-auto "> {/* ✅ FIX WIDTH HERE */}
                <div className="cursor-pointer" style={{ padding: "10px", paddingLeft: "22px" }}>
                    <svg width="20" height="20" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.10841 0.228163C7.39181 0.531457 7.39082 1.02214 7.10619 1.32413L2.48697 6.22512H15.2727L15.3714 6.2322C15.7264 6.28351 16 6.60775 16 7.00009C16 7.42809 15.6744 7.77505 15.2727 7.77505H2.48848L7.10624 12.6759L7.17682 12.7627C7.38866 13.0656 7.36602 13.4962 7.10837 13.7719C6.82494 14.0752 6.36446 14.0762 6.07986 13.7742L0.213264 7.5486L6.0799 0.225803Z"
                            fill="#393938"
                        />
                    </svg>
                </div>

                <div className="flex justify-center md:w-full">
                    <img src={physic} className="w-full" alt="image not found" />
                </div>

                <div className="form_wrapper w-full">
                    <div className="p-4 mt-2 font-semibold leading-27.24 tracking-tighter text-xl text-center">
                        <h1 style={{ fontSize: "20px", color: "#393938", fontWeight: "600" }}>
                            {step === 1 ? "Book A Physiotherapy Package At Your Convenience!" : "Review & Edit Details"}
                        </h1>
                    </div>

                    {step === 1 && (
                        <>
                            <InputText name="user_name" label="Name" value={formData.user_name} onChange={(e) => handleInputChange("user_name", e.target.value)} />
                            <InputText name="user_phone" label="Mobile Number" value={formData.user_phone} onChange={(e) => handleInputChange("user_phone", e.target.value)} />
                            <InputText name="package_amount" label="Package Amount" value={formData.package_amount} onChange={(e) => handleInputChange("package_amount", e.target.value)} />
                            <InputText name="sessions_booked" label="Sessions Booked" value={formData.sessions_booked} onChange={(e) => handleInputChange("sessions_booked", e.target.value)} />
                            <InputText
                                name="sessions_per_week"
                                label="Sessions Per Week"
                                type="number"
                                min="1"
                                max="7"
                                placeholder="1-7"  // ✅ Placeholder added
                                value={formData.sessions_per_week}
                                onChange={(e) => handleInputChange("sessions_per_week", e.target.value)}
                            />

                            {/* Wrapper to match InputText spacing & alignment */}
                            <div className="mx-6 my-3 flex flex-col">
                                <label className="block text-sm font-medium text-gray-700">Start Date</label>
                                <div className="w-full relative">
                                    <DatePicker
                                        selected={formData.package_start_date ? new Date(formData.package_start_date) : null} // ✅ Start empty
                                        onChange={(date) => handleInputChange("package_start_date", date)}
                                        dateFormat="EEE MMM dd yyyy"  // ✅ Format like "Mon Mar 17 2025"
                                        className="border-2 text-lg rounded-lg px-2 py-1 mb-2 w-full"
                                        placeholderText={new Date().toDateString()}  // ✅ Placeholder will show today’s date
                                    />

                                </div>
                            </div>
                            <div className="flex justify-center mt-4">
                                <Button btnName="Proceed" style="text-center text-white font-semibold text-lg px-6 mb-5 py-2 bg-orange-500 rounded-xl w-full" onClick={(e) => handleProceed(e)} />
                            </div>
                        </>
                    )}

                    {/* Step 2: Preview & Edit */}
                    {step === 2 && (
                        <>
                            {/* ✅ Ensure User ID, Doctor ID, User Status are also aligned */}
                            <InputText name="user_name" label="Name" value={formData.user_name} onChange={(e) => handleInputChange("user_name", e.target.value)} />
                            <InputText
                                name="user_id"
                                label="User ID"
                                value={fetchedData?.user_id || formData.user_id || ""}
                                onChange={(e) => handleInputChange("user_id", e.target.value)}
                            />
                            <InputText
                                name="therapist_id"
                                label="Therapist ID"
                                value={fetchedData?.doctor_id || formData.therapist_id || ""}
                                onChange={(e) => handleInputChange("therapist_id", e.target.value)}
                            />
                            <InputText name="package_amount" label="Package Amount" value={formData.package_amount} onChange={(e) => handleInputChange("package_amount", e.target.value)} />
                            <InputText name="sessions_booked" label="Sessions Booked" value={formData.sessions_booked} onChange={(e) => handleInputChange("sessions_booked", e.target.value)} />
                            <InputText name="sessions_per_week" label="Sessions Per Week" type="number" min="1" max="7" value={formData.sessions_per_week} onChange={(e) => handleInputChange("sessions_per_week", e.target.value)} />
                            <div className="mx-6 my-3 flex items-center w-full">
                                <div className="w-9/10 relative">
                                    <label className="block text-sm font-medium text-gray-700">Package Start Date</label>
                                    <input
                                        type="text"
                                        value={formData.package_start_date ? new Date(formData.package_start_date).toDateString() : ""}
                                        readOnly
                                        className="border border-gray-300 rounded-md px-2 py-1 w-full shadow-sm text-gray-900"
                                    />
                                    {/* ✅ Fix: Place DatePicker inside the same div to avoid extra rendering */}
                                    {isDatePickerOpen && (
                                        <DatePicker
                                            selected={formData.package_start_date ? new Date(formData.package_start_date) : null}
                                            onChange={(date) => {
                                                handleInputChange("package_start_date", date);
                                                setDatePickerOpen(false);
                                            }}
                                            onClickOutside={() => setDatePickerOpen(false)}
                                            open={isDatePickerOpen}
                                            dateFormat="EEE MMM dd yyyy"
                                            className="absolute top-full left-0 z-50 bg-white shadow-md p-2 rounded-md"
                                        />
                                    )}
                                </div>
                                {/* ✅ Edit Icon to open DatePicker */}
                                <button
                                    type="button"
                                    className="w-1/10 flex justify-center items-center ml-2 text-gray-500 hover:text-gray-700"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setDatePickerOpen(true);
                                    }}
                                >
                                    <FaEdit size={18} />
                                </button>
                            </div>
                            <InputText
                                name="user_status"
                                label="User Status"
                                value={fetchedData?.user_status || formData.user_status || ""}
                                onChange={(e) => handleInputChange("user_status", e.target.value)}
                            />
                            {/* Centering Submit Button */}
                            <div className="flex justify-center mt-4">
                                <Button btnName="Submit Package" style="text-center text-white font-semibold text-lg px-6 mb-5 py-2 bg-orange-500 rounded-xl w-full" onClick={handleSubmit} />
                            </div>
                        </>
                    )}

                    {error && <p className="text-red-500 text-center mt-2">{error}</p>}
                </div>
            </div>
        </form>

    );
};

export default PackageForm;
