import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPhoneAlt, FaTimes } from 'react-icons/fa';
import '../css/popup.css';  // Ensure this is correctly imported

const Popup = ({ onClose }) => {
  const navigate = useNavigate();

  return (
    <div className="popup">
      <button className="close-button" onClick={onClose}>
        <FaTimes className="close-icon"/>
      </button>
      <h2 className="popup-head">Can't Find a Slot?</h2>
      <p>Please choose one of the options below:</p>
      <div className="popup-buttons">
        <button className="popup-button1" onClick={() => {
          navigate('/request-slot');
          onClose();
        }}>
          Request Another Time
        </button>
        <button className="popup-button2" onClick={() => window.location.href = 'tel:+917949106799'}>
          <FaPhoneAlt  className="phone-icon"  /> Give Us a Call
        </button>
      
      </div>
    </div>
  );
};

export default Popup;
