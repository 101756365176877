import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../css/booking.css";
import TherapistCard from "../components/TherapistCard";
import Back from "../assets/images/backArrow.svg";
import DateCard from "../components/DateCard";
import TimeSlotCard from "../components/TimeSlotCard";
import { getTimeSlotOfTherapist } from "../api/route";
import { CircularProgress } from "@mui/joy";
import mixpanel from "../mixpanel"
import Loader from "../components/Loader";
import Popup from "../components/Popup";
import SessionDetail from "../components/SessionDetail/sessionDetail";
import AboutPhysica from "../components/About/aboutPhysica";
import instance from "../api/axios";

const TherapistPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [therapist, setTherapist] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(0, 10)); // Standardize date format
  const [timeSlots, setTimeSlots] = useState([]);
  const [allTimeSlots, setAllTimeSlots] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
   
  const [allAvailable, setAllAvailable] = useState([]);
  const [morningSlots, setMorningSlots] = useState([]);
  const [noonSlots, setNoonSlots] = useState([]);
  const [eveningSlots, setEveningSlots] = useState([]);

  const [showMore, setShowMore] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const doctorId = location.state?.doctorId;
    if (!doctorId) {
      navigate("/");
      return;
    }
    fetchTherapistDetails(doctorId);
  }, []);

  useEffect(() => {
    if (selectedDate) {
      localStorage.setItem('selectedDate', selectedDate);
    }
   
  }, [selectedDate, selectedTimeSlot]);
  
  useEffect(() => {
    console.log("Available Dates:", allAvailable);
    console.log("All Time Slots:", allTimeSlots);
    console.log("Selected Date Slots:", timeSlots);
  }, [allAvailable, allTimeSlots, timeSlots]);
  
  const fetchTherapistDetails = async (doctorId) => {
    setLoading(true);
    try {
      const therapistResponse = await instance.get(`/getTherapistById/${doctorId}`);
      setTherapist(therapistResponse.data.therapist);
      fetchTimeSlots(therapistResponse.data.therapist.dayschedule_id, selectedDate);
    } catch (error) {
      console.error("Failed to fetch therapist details:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTimeSlots = async (dayscheduleId, date) => {
    console.log("Fetching time slots for date and dayscheduleId for testinggggg:", date, dayscheduleId);
    setLoader(true);
    try {
      const response = await instance.get(`${getTimeSlotOfTherapist}/${dayscheduleId}/${date}`);
      console.log("Fetched Time Slots:", response.data);
      if (response.data && response.data.timeSlots) {
        processTimeSlots(date, response.data.timeSlots);
    }// Pass date to process function
    } catch (error) {
      console.error("Failed to fetch time slots:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    console.log("Checking dayscheduleId availability:", therapist?.dayschedule_id);
    if (therapist?.dayschedule_id && selectedDate) {
      fetchTimeSlots(therapist.dayschedule_id, selectedDate);
    }
  }, [therapist?.dayschedule_id, selectedDate]);
  
  const processTimeSlots = (date, timeSlotsData) => {
    if (!timeSlotsData || !Array.isArray(timeSlotsData.slots)) {
      console.error("Time slots data is not in expected format:", timeSlotsData);
      return;
    }

    // Initialize newSlots inside the function to avoid the undefined error
    const newSlots = timeSlotsData.slots.reduce((acc, curr) => {
      const filteredSlots = curr.hours.filter(slot => slot.available === 1);
      if (filteredSlots.length > 0) {
        acc[curr.date] = (acc[curr.date] || []).concat(
          filteredSlots.map(slot => ({
            time: slot.time,
            available: slot.available
          }))
        );
      }
      return acc;
    }, {});

    // Combine with existing slots while avoiding duplication
    const combinedSlots = { ...allTimeSlots, ...newSlots };
    setAllTimeSlots(combinedSlots);
    setAllAvailable(Object.keys(combinedSlots)); // Update available dates
  };



  // Update time slot segmentation
  const updateSlotSegments = (slots) => {
    // Define morning, noon, and evening slots
    const morning = slots.filter(slot => {
      const hour = new Date(slot.time).getHours();
      return hour >= 8 && hour < 12;
    });
    const noon = slots.filter(slot => {
      const hour = new Date(slot.time).getHours();
      return hour >= 12 && hour < 16;
    });
    const evening = slots.filter(slot => {
      const hour = new Date(slot.time).getHours();
      return hour >= 16 && hour < 21;
    });

    // Update state
    setMorningSlots([...morning]); // Spread to ensure fresh array
    setNoonSlots([...noon]);
    setEveningSlots([...evening]);
  };

  useEffect(() => {
    if (selectedDate && allTimeSlots[selectedDate]) {
      const slotsForDate = allTimeSlots[selectedDate];
      console.log("Slots for date:", slotsForDate); // Log to verify
      setTimeSlots(slotsForDate);
      updateSlotSegments(slotsForDate);  // Call updateSlotSegments here
    }
  }, [selectedDate, allTimeSlots]);

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);  // Update the selected date state
    fetchTimeSlots(therapist.dayschedule_id, newDate);  // Fetch new slots for the selected date
  };

  const handleTimeSlotSelection = (timeSlot) => {
    setSelectedTimeSlot(timeSlot);
  };

  const handleProceed = () => {
    if (selectedTimeSlot) {
      mixpanel.track('Proceed to Booking Detail', {
        'Therapist ID': therapist.dayschedule_id,
        'Selected Time Slot': selectedTimeSlot.time,
        'Date': selectedDate
      });
      navigate("/booking-detail", {
        state: {
          therapistId: therapist.dayschedule_id,
          timing: selectedTimeSlot,
        },
      });
    }
  };

  if (loading) {
    return <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}><CircularProgress /></div>;
  }

  return (
    <>
      {loader && <Loader />}
      {!loader && (
        <div className="BookingContainer">
          <div className="BookingWrapper">
            <div className="mt-2 cursor-pointer" onClick={() => navigate(-1)}>
              <img src={Back} alt="Back button" />
            </div>
            <div className="BookingHeading">
              <p>
                Book an appointment with <br />
                <strong>Physica Physiotherapy</strong>
              </p>
            </div>
            <AboutPhysica setShowMore={setShowMore} showMore={showMore} />
            <TherapistCard
              item={therapist}
              selectedDoctorId={null} // Assuming you also want to neutralize selection highlights
              setSelectedDoctorId={() => { }} // No-op function, does nothing when called
            />

            <SessionDetail />
            <div className="ChooseTherapistContainer">
              <h1>Select a Date</h1>
              <div className="TherapistCards DateCardWrapper2">
                {allAvailable.map(date => {
                  const dateData = { date, slots: allTimeSlots[date] || [] };
                  console.log("Passing to DateCard:", dateData);  // Log to check the structure
                  return (
                    <DateCard
                      key={date}
                      selectedDate={selectedDate}
                      data={dateData}
                      setselectedDate={handleDateChange}
                    />
                  );
                })}
              </div>
            </div>

            <div className="TimingHeading">
              <h1>Time</h1>
              <div className="MorningDiv">
                <div className="MorningDivHeading">
                  <p>Morning</p>
                  <p>8:00 AM- 12:00PM</p>
                </div>
                <div className="TimeSlots">
                  {morningSlots.map(slot => (
                    <TimeSlotCard
                      key={slot.time}
                      time={slot.time}
                      available={slot.available}
                      isSelected={selectedTimeSlot?.time === slot.time}
                      onClick={() => handleTimeSlotSelection(slot)}
                    />
                  ))}
                </div>
              </div>
              <div className="MorningDiv">
                <div className="MorningDivHeading">
                  <p>Noon</p>
                  <p>12:00 PM- 4:00PM</p>
                </div>
                <div className="TimeSlots">
                  {noonSlots.map(slot => (
                    <TimeSlotCard
                      key={slot.time}
                      time={slot.time}
                      available={slot.available}
                      isSelected={selectedTimeSlot?.time === slot.time}
                      onClick={() => handleTimeSlotSelection(slot)}
                    />
                  ))}
                </div>
              </div>
              <div className="MorningDiv">
                <div className="MorningDivHeading">
                  <p>Evening</p>
                  <p>4:00 PM- 9:00PM</p>
                </div>
                <div className="TimeSlots">
                  {eveningSlots.map(slot => (
                    <TimeSlotCard
                      key={slot.time}
                      time={slot.time}
                      available={slot.available}
                      isSelected={selectedTimeSlot?.time === slot.time}
                      onClick={() => handleTimeSlotSelection(slot)}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="ProcessButton">
              <button
                onClick={handleProceed}
                disabled={!selectedTimeSlot}
                style={{
                  backgroundColor: !selectedTimeSlot
                    ? "rgba(165, 165, 165, 1)"
                    : "rgb(249, 115, 22)",
                }}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TherapistPage;
