import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import instance from "../api/axios";
import "../css/therapistSlot.css";
import mixpanel from "../mixpanel"
import Back from "../assets/images/backArrow.svg";
import { CircularProgress } from "@mui/joy";
import FullPagePopup from "../components/FullPagePopup";
import RequestAboutPhysica from "../components/About/RequestaboutPhysica";

const TherapistSlot = () => {
  const { therapistId } = useParams();
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);
  const [therapist, setTherapist] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(0, 10));
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const handleBackButtonClick = () => {
    navigate(-1);
  };
  useEffect(() => {
    const fetchTherapistDetails = async () => {
      setLoading(true);
      try {
        const response = await instance.get(`/getTherapistById/${therapistId}`);
        setTherapist(response.data.therapist);
        fetchTimeSlots(response.data.therapist.dayschedule_id, selectedDate);
      } catch (error) {
        console.error("Failed to fetch therapist details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTherapistDetails();
  }, [therapistId, selectedDate]);

  const fetchTimeSlots = async (dayscheduleId, date) => {
    if (!dayscheduleId) return;
    setLoading(true);
    try {
      const response = await instance.get(`/therapistTimeSlot/${dayscheduleId}/${date}`);
      const availableSlots = response.data.timeSlots.slots.filter(slot => slot.hours.some(hour => hour.available === 1));
      setTimeSlots(availableSlots);  // Set the fetched slots
    } catch (error) {
      console.error("Failed to fetch time slots:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBookingButtonClick = () => {
    if (timeSlots.length > 0) {
      mixpanel.track('Button Clicked - Continue with your Doctor', { doctorId: therapistId });
      navigate('/therapistPage', { state: { doctorId: therapistId } });
    } else {
      mixpanel.track('Popup Opened - No Available Slots', { doctorId: therapistId });
      setShowPopup(true);
    }
  };

  if (showPopup) {
    return <FullPagePopup navigate={navigate} />;
  }

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress color="neutral" size="md" variant="plain" />
      </div>
    );
  }

  return (
    <>
      <div className="BookingContainer">
        <div className="BookingWrapper">
          <div className="mt-2 cursor-pointer" onClick={handleBackButtonClick}>
            <img src={Back} alt="Back button" />
          </div>
          <div className="BookingHeading">
            <p>Book an appointment with <br /><strong>Physica Physiotherapy</strong></p>
          </div>
         
          <div className="no-slots-container">
            <h1>You are booking your appointment with Dr. {therapist ? therapist.doctor_name : "Loading..."}</h1>
            <p>Please choose one of the options below:</p>
            <div className="thep-buttons">
              <button className="thep-button1" onClick={handleBookingButtonClick}>
                Continue with your Doctor 
              </button>
              <button className="thep-button2" onClick={() => {
                 mixpanel.track('Button Clicked - View Slots for All Doctor\'s');
                navigate('/booking-slot')}}>
                View Slots for All Doctor's
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TherapistSlot;
