const RequestAboutPhysica = ({
    handleChooseTherapistClick,
    setShowMore,
    showMore,
  }) => {
    return (
      <div className="AboutPhysica">
        <div className="flex items-center">
          <h1>About Physica</h1>
          {/* <a className="pl-2 pt-1" onClick={handleChooseTherapistClick}>
            Choose Therapist {">"}
          </a> */}
        </div>
        <p>
          Our professional doctors will visit your home for an 40 minute treatment
          session for your rehabilitation needs.
        </p>
        {!showMore && <a onClick={() => setShowMore(true)}>Read More</a>}
        {showMore && (
          <>
            <p>
              Our doctors are experienced, certified and trained in relieving body
              pain, stroke recovery, MSK rehabilitation and post surgery care.
            </p>
            <p>
              With our consistent 5-star rating and proven recovery data, we are
              recognized as one of Mumbai's top providers of physiotherapy care.
            </p>
            <p>
              Book a session today to experience the exceptional care of Physica
              firsthand.
            </p>
            <a onClick={() => setShowMore(false)}>Read Less</a>
          </>
        )}
      </div>
    );
  };
  
  export default RequestAboutPhysica;
  