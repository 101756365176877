import { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import "../css/booking.css";

import Back from "../assets/images/backArrow.svg";
import DateCard from "../components/DateCard";
import RequestTimeSlotCard from "../components/RequestTimeSlotCard"
import { CircularProgress } from "@mui/joy";
import mixpanel from "../mixpanel";
import SessionDetail from "../components/SessionDetail/sessionDetail";
import RequestAboutPhysica from "../components/About/RequestaboutPhysica";
import useDraggableScroll from "../hooks";

const RequestSlot = () => {

  const todayDate = new Date().toLocaleString("en-US", {
    timeZone: "Asia/Kolkata",
  });
  const today = new Date(todayDate);
  const [showMore, setShowMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [selectedDate, setselectedDate] = useState(
    today.toISOString().split("T")[0]
  );

  const [showTherapistSection, setShowTherapistSection] = useState(false);

  const navigate = useNavigate();

  const generateNext3Days = () => {
    const days = [];
    for (let i = 0; i < 3; i++) {
      const date = new Date();
      date.setDate(date.getDate() + i); // Add i days to the current date
      const formattedDate = date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
      days.push({
        date: formattedDate,
        slots: true, // You can modify this based on availability logic
      });
    }
    return days;
  };

  const allAvailable = generateNext3Days();


  const formatDateToIST = (date) => {
    // Convert date to a string in the 'Asia/Kolkata' timezone
    const istDate = new Date(
      date.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
    );
    return istDate;
  };

  const renderTimeSlots = useMemo(
    () => (startTime, endTime) => {
      const slots = [];
      let currentTime = formatDateToIST(new Date(selectedDate)); // Create a new Date for the selected day
      currentTime.setHours(startTime, 0, 0, 0); // Set the initial time to startTime:00

      while (currentTime.getHours() < endTime) {
        const timeForThisSlot = currentTime.toISOString();
        const dateTime = new Date(timeForThisSlot); // Ensure `time` is a valid Date object

        // Convert 24-hour time to 12-hour format
        function convertTo12HourFormat(hours24, minutes) {
          const ampm = hours24 >= 12 ? "PM" : "AM";
          let hours12 = hours24 % 12;
          if (hours12 === 0) {
            hours12 = 12; // Handle midnight and noon
          }
          // Pad minutes with a leading zero if it's a single digit
          const paddedMinutes = minutes.toString().padStart(2, "0");
          return `${hours12}:${paddedMinutes} ${ampm}`;
        }

        // Extract hours and minutes directly from the Date object
        console.log(`${dateTime.getDate()}-${dateTime.getMonth()}-${dateTime.getFullYear()}`)
        const hours = dateTime.getHours();
        const minutes = dateTime.getMinutes();

        const formattedTime = convertTo12HourFormat(hours, minutes);
        slots.push(
          <RequestTimeSlotCard
            key={formattedTime}
            time={formattedTime} // Pass the full ISO string or Date object
            available={1} // Assuming the slot is available (you can modify this condition)
            isSelected={selectedTimeSlot?.time === formattedTime}
            onClick={(time) => {
              console.log(dateTime)
              setSelectedTimeSlot({time , date : `${dateTime.getDate()}-${dateTime.getMonth()}-${dateTime.getFullYear()}`});
            }}
          />
        );

        // Increment by 30 minutes
        currentTime.setMinutes(currentTime.getMinutes() + 30);
      }

      return slots;
    },
    [selectedDate, selectedTimeSlot] // Include dependencies that might affect the slot generation
  );

  const handleProceed = () => {
    if (selectedTimeSlot) {
      mixpanel.track("Button Clicked - Proceed to Request Slot Booking", {
        "Selected Time": selectedTimeSlot?.time,
        "Selected Date": selectedTimeSlot?.date
    });
      navigate("/request-booking-detail", {
        state: {
          timing: selectedTimeSlot?.time,
          date : selectedTimeSlot?.date
        },
      });
    }
  };

  const handleChooseTherapistClick = () => {
    setShowTherapistSection(!showTherapistSection);
  };

  const handleBackButtonClick = () => {
    // Clear the flag before navigating back
    sessionStorage.removeItem("redirectedFromSlotPage");
  
    // Navigate back
    navigate(-1);
  };
  

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="neutral" size="md" variant="plain" />
      </div>
    );
  }

  return (
    <>
      <div className="BookingContainer">
        <div className="BookingWrapper">
          <div className="mt-2 cursor-pointer" onClick={handleBackButtonClick}>
            <img src={Back} alt="Back button" />
          </div>
          <div className="BookingHeading">
            <p>
              Request an appointment with <br />
              <strong> Physica Physiotherapy</strong>
            </p>
          </div>
          <RequestAboutPhysica
            handleChooseTherapistClick={handleChooseTherapistClick}
            setShowMore={setShowMore}
            showMore={showMore}
          />
          <SessionDetail />
          <div className="ChooseTherapistContainer">
            <h1>Select a Date</h1>
            <div className="TherapistCards DateCardWrapper2">
              {allAvailable?.map((slot) => (
                <DateCard
                  selectedDate={selectedDate}
                  key={slot.date}
                  data={slot}
                  setselectedDate={setselectedDate}
                />
              ))}
            </div>
          </div>
          <div className="TimingHeading">
            <>
              <h1>Time</h1>

              <div className="MorningDiv">
                <div className="MorningDivHeading">
                  <p>Morning</p>
                  <p>8:00 AM- 12:00PM</p>
                </div>
                <div className="TimeSlots">{renderTimeSlots(8, 12)}</div>
              </div>

              <div className="MorningDiv">
                <div className="MorningDivHeading">
                  <p>Noon</p>
                  <p>12:00 PM- 4:00PM</p>
                </div>
                <div className="TimeSlots">{renderTimeSlots(12, 16)}</div>
              </div>

              <div className="MorningDiv">
                <div className="MorningDivHeading">
                  <p>Evening</p>
                  <p>4:00 PM- 9:00PM</p>
                </div>
                <div className="TimeSlots">{renderTimeSlots(16, 21)}</div>
              </div>
            </>
          </div>
          <div className="ProcessButton">
            <button
              onClick={handleProceed}
              disabled={!selectedTimeSlot}
              style={{
                backgroundColor: !selectedTimeSlot
                  ? "rgba(165, 165, 165, 1)"
                  : "rgb(249, 115, 22)",
              }}
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestSlot;