import React, { useState, useEffect } from "react";
import logo from '../assets/images/physica-logo.png';
import { useNavigate } from "react-router-dom";

const FullPagePopup = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("We're almost ready...");
  const [subMessage, setSubMessage] = useState("Loading Slots");
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    // Initial timer for showing the first message
    const timer = setTimeout(() => {
      // Begin transition by showing loader
      setShowLoader(true);
      setTimeout(() => {
        // Update the message and hide the loader
        setMessage("Physiotherapy ID doesn't exist...");
        setSubMessage("Loading All Slots");
        setShowLoader(false);
        setTimeout(() => {
          navigate('/request-slot'); // Redirect after the second message
        }, 2000);
      }, 2000); // Time to show the loader before switching message
    }, 2000); // Time before first change

    return () => clearTimeout(timer); // Clean up the timer
  }, [navigate]);

  // Inline styles
  const containerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(249, 246, 246, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
    color: 'black',
    textAlign: 'center',
    fontFamily: 'Open Sans, sans-serif',
  };

  const logoStyle = {
    width: 250,
    marginBottom: 70
  };

  const headingStyle = {
    fontSize: 26,
    fontWeight: '600',
    marginBottom: 70
  };

  const subTextStyle = {
    fontSize: 22,
    fontWeight: '500',
    marginBottom: 50
  };

  const loaderStyle = {
    display: 'flex',
    flexDirection: 'row', // Ensure that the loader is horizontal
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 100
  };

  const dotStyle = {
    height: '40px', // Larger size for visibility
    width: '10px',
    borderRadius: '10%',
    backgroundColor: '#FE8235', // Dark color for contrast
    animation: 'load8 1.4s infinite ease-in-out both',
    margin: '0 5px' // Add some spacing between dots
  };

  // Keyframes for loader animation
  const dotKeyframes = `
    @keyframes load8 {
      0%, 80%, 100% {
        transform: scale(0);
      }
      40% {
        transform: scale(1.0);
      }
    }
  `;

  return (
    <>
      <style>{dotKeyframes}</style>
      <div style={containerStyle}>
        {!showLoader && <img src={logo} alt="Physica Logo" style={logoStyle} />}
        {!showLoader ? (
          <>
            <h1 style={headingStyle}>{message}</h1>
            <p style={subTextStyle}>{subMessage}</p>
            <div style={loaderStyle}>
              <div style={dotStyle}></div>
              <div style={{...dotStyle, animationDelay: '-0.32s'}}></div>
              <div style={{...dotStyle, animationDelay: '-0.16s'}}></div>
            </div>
          </>
        ) : (
          <div style={loaderStyle}> {/* Show loader while transitioning messages */}
            <div style={dotStyle}></div>
            <div style={{...dotStyle, animationDelay: '-0.32s'}}></div>
            <div style={{...dotStyle, animationDelay: '-0.16s'}}></div>
          </div>
        )}
      </div>
    </>
  );
};

export default FullPagePopup;
