import React from "react";
import Image from "../assets/images/therapist.png";

const TherapistCard = ({ onClick, setSelectedDoctorId, item, selectedDoctorId }) => {
    return (
        <div
            className={
                selectedDoctorId === item?.dayschedule_id
                    ? "CardWrapper selectedCard"
                    : "CardWrapper"
            }
            onClick ={onClick}
        >
            <div className="TherapistImg">
                <img src={Image} />
            </div>
            <div className="TherapistDetail">
                <h1>{item?.doctor_name}</h1>
                <p>Available</p>
            </div>
        </div>
    );
};

export default TherapistCard;
