import React, { useCallback, useEffect, useRef, useState } from "react";
import dateIcon from "../../src/assets/images/date.png";
import timeIcon from "../../src/assets/images/time.png";
import feesIcon from "../../src/assets/images/rs.png";
import nameIcon from "../../src/assets/images/userName.svg";
import phoneIcon from "../../src/assets/images/userPhone.svg";
import pinIcon from "../../src/assets/images/userPincode.svg";
import editIcon from "../../src/assets/images/edit.svg";
import durationIcon from "../../src/assets/images/Stopwatch.png";
import Button from "../components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import instance from "../api/axios";
import { getTherapist, RequestcreateBooking } from "../api/route";
import EditFormModel from "../components/Models/editFormModel";
import RequestConfirmModel from "../components/Models/RequestConfirmModel";
import FailModel from "../components/Models/failModel";
import mixpanel from '../mixpanel';
import {
  GoogleMap,
  useLoadScript,
  MarkerF,
  Autocomplete,
} from "@react-google-maps/api";
import { CircularProgress } from "@mui/joy";

const RequestBookingDetails = () => {
  const libraries = ["places"];
  const mapContainerStyle = { width: "100%", height: "400px" };
  const center = { lat: 19.076, lng: 72.8777 };

  const [address, setAddress] = useState("");
  const [floor, setFloor] = useState("");

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openEditModel, setOpenEditModel] = useState(false);
  const [Fees, setFees] = useState(799);

  const [showRequestConfirmModel, setShowRequestConfirmModel] = useState(false);
  const [showFailModel, setShowFailModel] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [user, setUser] = useState(null);
  const [marker, setMarker] = useState(center);
  const [autocomplete, setAutocomplete] = useState(null);

  const mapRef = useRef();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { date, timing } = state;

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDMkKOvFYyjPU8u-tIR68V0ToZdeH1noGE",
    libraries,
  });

  const onLoad = useCallback((autocomplete) => {
    setAutocomplete(autocomplete);
  }, []);

  const onPlaceChanged = useCallback(() => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const location = place.geometry.location;
        setMarker({ lat: location.lat(), lng: location.lng() });
        setAddress(place.formatted_address);
        // mapRef.current.panTo({ lat: location.lat(), lng: location.lng() });
      }
    }
  }, [autocomplete]);

  useEffect(() => {
    const fetchTherapist = async () => {
      setLoading(true);
      const userDetail = sessionStorage.getItem("user");
      const user = JSON.parse(userDetail);
      setUser(user);
      setFees(user?.fees || 899);
      setLoading(false);
    };

    fetchTherapist();
  }, []);

  useEffect(() => {
    setButtonDisabled(address.trim() === "" || floor.trim() === "");
  }, [address, floor]);

  const handleAddressChange = (e) => {
    const { value } = e.target;
    setAddress(value);
  };

  const handleConfirmAppointment = async () => {
    setLoading(true);
    const userDetail = sessionStorage.getItem("user");
    const user = JSON.parse(userDetail);
    console.log("Test",user);
    const obj = {
      user_name: user.name,
      user_phone: user.phone,
      user_pincode: user.pinCode,
      user_address: `${floor} ${address}`,
      user_city: user.address,
      time: timing,
      date: date,
    };
    try {
      const response = await instance.post(RequestcreateBooking, obj);
      console.log(response?.data?.success);
      // Mixpanel track event for confirming a booking request
      mixpanel.track("Confirm Request Appointment", {
        "User Name": obj.user_name,
        "User Phone": obj.user_phone,
        "User Pincode": obj.user_pincode,
        "User Address": obj.user_address,
        "User City": obj.user_city,
        "Time": obj.time,
        "Date": obj.date,
        "Success": response?.data?.success
      });

      if (response?.data?.success) {
        setShowRequestConfirmModel(true);
      } else {
        setErrorMessage(response?.data?.message);
        setShowFailModel(true);

         // Track fail event in Mixpanel
         mixpanel.track("Failed Confirm Request Appointment", {
          "Error Message": response?.data?.message
        });
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      setShowFailModel(true);
       // Track error event in Mixpanel
       mixpanel.track("Error Confirm Request Appointment", {
        "Error Message": error?.response?.data?.message
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseConfirmModel = () => setShowRequestConfirmModel(false);
  const handleCloseFailModel = () => setShowFailModel(false);
  const handleBackButtonClick = () => navigate(-1);
  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="neutral" size="md" variant="plain" />
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="mt-4 mb-6 cursor-pointer" onClick={handleBackButtonClick}>
        <svg
          width="16"
          height="14"
          viewBox="0 0 16 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.10841 0.228163C7.39181 0.531457 7.39082 1.02214 7.10619 1.32413L2.48697 6.22512H15.2727L15.3714 6.2322C15.7264 6.28351 16 6.60775 16 7.00009C16 7.42809 15.6744 7.77505 15.2727 7.77505H2.48848L7.10624 12.6759L7.17682 12.7627C7.38866 13.0656 7.36602 13.4962 7.10837 13.7719C6.82494 14.0752 6.36446 14.0762 6.07986 13.7742L0.213264 7.5486L0.210346 7.54501C0.187782 7.5207 0.166718 7.49481 0.147311 7.4675L0.142491 7.46157C-0.0698261 7.15771 -0.046217 6.72568 0.213306 6.45032L6.0799 0.225803L6.16162 0.150955C6.44679 -0.0734968 6.85077 -0.0475585 7.10841 0.228163Z"
            fill="#393938"
          />
        </svg>
      </div>
      <div className="text-lg text-blue-900">
        <p className="font-semibold">Physiotherapy At Home </p>
        {/* <h2 className="font-bold text-blue-900">Dr. {therapist.doctor_name}</h2> */}
      </div>
     
      <div className="flex w-full my-6">
        <div className="flex w-full flex-col justify-center text-xl border-2 border-orange-500 rounded-lg p-4">
          <div className="flex justify-between items-start">
            <div className="text-lg">
              <p className="font-medium">Appointment Request </p>
              {/* <h2 className="font-bold">Dr. {therapist.doctor_name}</h2> */}
            </div>
            <img
              src={editIcon}
              className="cursor-pointer"
              onClick={() => setOpenEditModel(true)}
              alt="Edit Icon"
            />
          </div>
          <div className="mt-4 mb-4 text-lg flex flex-col gap-3">
            <p>
              <img
                src={nameIcon}
                alt="Date Icon"
                className="inline-block w-5 h-5 mx-2"
              />
              Name :<strong> {user?.name}</strong>
            </p>
            <p>
              <img
                src={phoneIcon}
                alt="Date Icon"
                className="inline-block w-5 h-5 mx-2"
              />
              Mobile :<strong> {user?.phone}</strong>
            </p>
            <p>
              <img
                src={pinIcon}
                alt="Date Icon"
                className="inline-block w-5 h-5 mx-2"
              />
              Pincode :<strong> {user?.pinCode}</strong>
            </p>
            <p>
              <img
                src={dateIcon}
                alt="Date Icon"
                className="inline-block w-5 h-5 mx-2"
              />
              Date :<strong> {date}</strong>
            </p>
            <p>
              <img
                src={timeIcon}
                alt="Time Icon"
                className="inline-block w-5 h-5 mx-2"
              />
              Time :<strong> {timing}</strong>
            </p>
            <p>
              <img
                src={feesIcon}
                alt="Fees Icon"
                className="inline-block w-4 h-4 mx-2"
              />
              Fees :<strong> ₹{Fees}</strong>
            </p>
            <p>
              <img
                src={durationIcon}
                alt="Duration Icon"
                className="inline-block w-5 h-5 mx-2"
              />
              Duration :<strong> 40 mins</strong>
            </p>
          </div>
        </div>
      </div>

      <div className="my-2">
        <p className="text-lg font-semibold">
          {buttonDisabled
            ? "Complete Your Address to proceed"
            : "Complete Address"}
        </p>

        <Autocomplete
          options={{
            componentRestrictions: {
              country: "in",
            },
          }}
          onLoad={onLoad}
          onPlaceChanged={onPlaceChanged}
        >
          <input
            type="text"
            placeholder="Enter an Address"
            value={address}
            onChange={handleAddressChange}
            className="border-2 rounded-lg px-4 py-2 mt-2 w-full"
          />
        </Autocomplete>

        <input
          type="text"
          placeholder="Enter an Apartment/ House/ Floor Number"
          value={floor}
          onChange={(e) => setFloor(e.target.value)}
          className="border-2 rounded-lg px-4 py-2 mt-2 w-full"
        />
      </div>

      <div className="mt-8 p-4">
        <Button
          btnName="Send Appointment Request"
          style={`text-center w-full font-semibold text-lg px-6 py-2 ${
            buttonDisabled
              ? "bg-neutral-400 text-gray-200"
              : "bg-orange-500 text-white"
          } rounded-xl`}
          onClick={handleConfirmAppointment}
          disabled={buttonDisabled}
        />
      </div>
      {openEditModel && <EditFormModel setopenEditModel={setOpenEditModel} />}
      {showRequestConfirmModel && (
        <RequestConfirmModel
          onClose={handleCloseConfirmModel}
          date={date}
          time={timing}
        />
      )}
      {showFailModel && (
        <FailModel onClose={handleCloseFailModel} errorMessage={errorMessage} />
      )}
    </div>
  );
};

export default RequestBookingDetails;