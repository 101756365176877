import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

function RouteTracker() {
  const location = useLocation();

  useEffect(() => {
    // Track every page/route change
    mixpanel.track('Page Viewed', {
      'Page Path': location.pathname
    });
  }, [location]); // Re-run the effect only if location changes

  return null; // This component does not render anything
}

export default RouteTracker;