import React from "react";
import Image from "../assets/images/therapist.png";

const RequestTimeSlotCard = ({ time, available, users, onClick, isSelected }) => {
  

  return (
    <div
      className={`TimeSlotWrapper ${isSelected ? "TimeSlotWrapper timeSelectedCard" : "TimeSlotWrapper"
        }`}
      onClick={()=>onClick(time)}
    >
      <p
        className="text-center"
        style={{ fontSize: "14px", fontWeight: "400" }}
      >
        {time}
      </p>
    </div>
  );
};

export default RequestTimeSlotCard;
