import { BrowserRouter, Route, Routes } from "react-router-dom";
import Form from "./pages/Form";
import SlotPage from "./pages/SlotPage";
import BookingDetails from "./pages/BookingDetails";
import RequestSlot from "./pages/RequestSlotPage";
import RequestBookingDetails from "./pages/RequestBookingDetails";
import EmptySlots from "./pages/EmptySlots";
import AppointmentConfirmation from "./pages/AppointmentConfirmation";
import TherapistSlot from "./pages/therapistSlot";
import TherapistPage from "./pages/therapistPage";
import PackageForm from "./pages/PackageForm";
import mixpanel from 'mixpanel-browser';
import RouteTracker from "./RouteTracker";

mixpanel.init("af4fa557ae3efe213a6374391a212ef6", {
  debug: true
});
function App() {

  return (
    <BrowserRouter>
    <RouteTracker />
      <Routes>
        <Route path="/" element={<Form />} />
        <Route path="/:therapistId" element={<Form />} />
        <Route path="/booking-slot" element={<SlotPage />} />
        <Route path="/booking-detail" element={<BookingDetails />} />
        <Route path="/request-slot" element={<RequestSlot />} />
        <Route path="/request-booking-detail" element={<RequestBookingDetails />} />
        <Route path="/empty-slot" element={<EmptySlots />} />
        <Route path="/appointment-confirmation" element={<AppointmentConfirmation />} />
        <Route path="/therapist-slot/:therapistId" element={<TherapistSlot />} />
        <Route path="/therapistPage" element={<TherapistPage/>} />
        <Route path="/internal-package" element={<PackageForm />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;