import React from "react";
import mixpanel from "../mixpanel"
const DateCard = ({ selectedDate, data, setselectedDate }) => {
    const dateTime = new Date(data.date);
    const dayOfWeek = dateTime.getDay();
    const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    const day = daysOfWeek[dayOfWeek];

    const isSelected = selectedDate === data.date;
    const isAvailable = data.slots;

    const cardClassName = `DateCardWrapper ${isSelected ? 'selected' : ''}`;
    const detailClassName = `DateCardDetail ${isSelected ? 'selectedCardDate' : ''} ${isAvailable ? '' : 'bg-gray-200'}`;

    const handleClick = () => {
        if (data.slots) {
            setselectedDate(data.date);
            mixpanel.track("Button Clicked - Date Button", {
                "Selected Date": data.date,
                "Availability": data.slots ? 'Available' : 'Unavailable'
            });
        }
    };

    const convertDateFormat = (dateString) => {
        const dateParts = dateString.split('-');
        const day = parseInt(dateParts[2]);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const monthIndex = parseInt(dateParts[1]) - 1;
        const month = monthNames[monthIndex];
        const daySuffix = getDaySuffix(day); // Function to get the suffix for the day
        const paddedDay = day < 10 ? `0${day}` : day;
        return `${paddedDay}${daySuffix} ${month}`;
    };

    const getDaySuffix = (day) => {
        if (day >= 11 && day <= 13) {
            return 'th';
        }
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };

    return (
        <div className={cardClassName} onClick={handleClick}>
            <p>{day}</p>
            <div className={detailClassName}>
                <h1>{convertDateFormat(data.date)}</h1>
                <p>{isAvailable ? 'Available' : 'Unavailable'}</p>
            </div>
        </div>
    );
};

export default DateCard;
