import React from "react";
import ConfirmImg from "../../assets/images/confirm.svg";
import dateIcon from "../../assets/images/dateGray.png";
import timeIcon from "../../assets/images/timeGray.png";
import "./confirmModel.css";

const RequestConfirmModel = ({ onClose, date, time }) => {
  return (
    <div className="confirmModelContainer ">
      <div className="confirmModelWrapper px-4">
        <div className="flex justify-end cursor-pointer" onClick={onClose}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.7338 0.275313C11.3788 -0.0796357 10.8055 -0.0796357 10.4505 0.275313L6 4.71672L1.54949 0.266212C1.19454 -0.0887372 0.62116 -0.0887372 0.266212 0.266212C-0.0887372 0.62116 -0.0887372 1.19454 0.266212 1.54949L4.71672 6L0.266212 10.4505C-0.0887372 10.8055 -0.0887372 11.3788 0.266212 11.7338C0.62116 12.0887 1.19454 12.0887 1.54949 11.7338L6 7.28328L10.4505 11.7338C10.8055 12.0887 11.3788 12.0887 11.7338 11.7338C12.0887 11.3788 12.0887 10.8055 11.7338 10.4505L7.28328 6L11.7338 1.54949C12.0796 1.20364 12.0796 0.621161 11.7338 0.275313Z"
              fill="#393938"
            />
          </svg>
        </div>
        <div className="flex flex-col w-full justify-center items-center text-center text-neutral-400 text-base py-4 px-6">
          <div className="mb-2">
            <img src={ConfirmImg} alt="Confirmation" />
          </div>
          <div className="mt-3">
            <h1 className="text-2xl font-bold text-black">
              Request Shared!
            </h1>
            <p className="pt-4 pb-6 border-b-2">
            We have received your appointment request and we will be in touch with you with the confirmation very soon.
              Please contact us on +917949106799 if urgent
            </p>
          </div>
          <div className="mt-4 mb-2">
            <div className="flex p-3 justify-center items-center">
              <p className="px-2 py-1 border-r-2 flex items-center">
                <img
                  src={dateIcon}
                  alt="Date Icon"
                  className="inline-block w-5 h-5 mx-2"
                />
                {date}
              </p>
              <p className="px-2 py-1 flex items-center">
                <img
                  src={timeIcon}
                  alt="Time Icon"
                  className="inline-block w-5 h-5 mx-2"
                />
                {time}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestConfirmModel;
