import { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import "../css/booking.css";
import mixpanel from "../mixpanel"
import Back from "../assets/images/backArrow.svg";
import DateCard from "../components/DateCard";
import RequestTimeSlotCard from "../components/RequestTimeSlotCard"
import { CircularProgress } from "@mui/joy";
import { FaPhoneAlt, } from 'react-icons/fa';
import SessionDetail from "../components/SessionDetail/sessionDetail";
import RequestAboutPhysica from "../components/About/RequestaboutPhysica";


const EmptySlots = () => {

    const todayDate = new Date().toLocaleString("en-US", {
        timeZone: "Asia/Kolkata",
    });
    const today = new Date(todayDate);
    const [showMore, setShowMore] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
    const [selectedDate, setselectedDate] = useState(
        today.toISOString().split("T")[0]
    );

    const [showTherapistSection, setShowTherapistSection] = useState(false);

    const navigate = useNavigate();

    const generateNext3Days = () => {
        const days = [];
        for (let i = 0; i < 3; i++) {
            const date = new Date();
            date.setDate(date.getDate() + i); // Add i days to the current date
            const formattedDate = date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
            days.push({
                date: formattedDate,
                slots: true, // You can modify this based on availability logic
            });
        }
        return days;
    };

    const allAvailable = generateNext3Days();
    const handleProceed = () => {

    };

    const handleBackButtonClick = () => {
        navigate(-1);
    };



    if (loading) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <CircularProgress color="neutral" size="md" variant="plain" />
            </div>
        );
    }

    return (
        <>
            <div className="BookingContainer">
                <div className="BookingWrapper">
                    <div className="mt-2 cursor-pointer" onClick={handleBackButtonClick}>
                        <img src={Back} alt="Back button" />
                    </div>
                    <div className="BookingHeading">
                        <p>
                            Request an appointment with <br />
                            <strong> Physica Physiotherapy</strong>
                        </p>
                    </div>
                    <RequestAboutPhysica

                        setShowMore={setShowMore}
                        showMore={showMore}
                    />
                    <SessionDetail />
                    <div className="ChooseTherapistContainer">
                        <h1>Select a Date</h1>
                        <div className="TherapistCards DateCardWrapper2">
                            {allAvailable?.map((slot) => (
                                <DateCard
                                    selectedDate={selectedDate}
                                    key={slot.date}
                                    data={slot}
                                    setselectedDate={setselectedDate}
                                />
                            ))}
                        </div>
                    </div>


                    {/* <div className="ProcessButton">
                        <button
                            onClick={handleProceed}
                            style={{
                                backgroundColor: "rgba(165, 165, 165, 1)"
                            }}
                            disabled={true}
                        >
                            Proceed
                        </button>
                    </div> */}
                    <div className="EmptyTimingHeading">
                        <h1>Time</h1>
                    </div>

                    <div className="no-slots-container">
                        <h1>No currently available slots in your area</h1>
                        <p>Please choose one of the options below:</p>
                        <div className="popup-buttons">
                            <button className="popup-button1" onClick={() => {
                                mixpanel.track('Button Clicked - Request another time slot on Empty Slots');
                                navigate('/request-slot');

                            }}>
                                Request Another Time
                            </button>
                            <button className="popup-button2" onClick={() => {
                                mixpanel.track('Button Clicked - Give us a call on Empty Slots');
                                window.location.href = 'tel:+917949106799'
                            }}>
                                <FaPhoneAlt className="phone-icon" /> Give Us a Call
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EmptySlots;